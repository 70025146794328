/* @import "~antd/dist/antd.css"; */
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

.App {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
}

.content {
  padding: 1rem;
}

.danger-button {
  background-color: rgb(255, 0, 0) !important;

  &:hover {
    background-color: rgb(255, 84, 84) !important;
  }
}

.dropdown-parsing .ant-btn-compact-first-item {
  flex: 1 !important;
}